import React from "react"
import SwiperCore, { Navigation, A11y } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import Spacer from "../elements/spacer"

import { data } from "../../data/daily_challenges"
import { useDesktop } from "src/hooks/useDesktop"

SwiperCore.use([Navigation, A11y])

const InstagramFeed = () => {
  const isDesktop = useDesktop()

  const dailyChallenge = data

  const challengeQuestion = (id: string) => {
    return (
      <>
        <div className="challenge-question__welcome-text caption1">
          Challenge {id} / {data.length} @rightbrainworkout
        </div>
        <Spacer deviceSize="m" desktopSize="l" />
      </>
    )
  }
  const day1el = document.querySelector("#rbw-d1")
  const day2el = document.querySelector("#rbw-d2")
  const day3el = document.querySelector("#rbw-d3")
  const day4el = document.querySelector("#rbw-d4")
  const day5el = document.querySelector("#rbw-d5")
  const day6el = document.querySelector("#rbw-d6")
  const day7el = document.querySelector("#rbw-d7")
  const day8el = document.querySelector("#rbw-d8")
  const day9el = document.querySelector("#rbw-d9")
  const day10el = document.querySelector("#rbw-d10")
  const day11el = document.querySelector("#rbw-d11")
  const day12el = document.querySelector("#rbw-d12")
  const day13el = document.querySelector("#rbw-d13")
  const day14el = document.querySelector("#rbw-d14")

  React.useEffect(() => {
    if (day1el) {
      day1el.classList.remove("hidden")
      day1el.classList.add("visible")
    }
    if (day2el) {
      day2el.classList.remove("visible")
      day2el.classList.add("hidden")
    }
  }, [day1el, day2el])

  const handleSlideChange = (e: any) => {
    console.log("e", e.activeIndex)
    if (e.activeIndex === 0) {
      console.log("show day 1", day1el)
      if (day1el && day2el) {
        day1el.classList.add("visible")
        day1el.classList.remove("hidden")
        day2el.classList.add("hidden")
        day2el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 1) {
      if (day1el && day2el && day3el) {
        console.log("show day 2", day2el)
        day1el.classList.add("hidden")
        day1el.classList.remove("visible")
        day2el.classList.add("visible")
        day2el.classList.remove("hidden")
        day3el.classList.add("hidden")
        day3el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 2) {
      if (day2el && day3el && day4el) {
        console.log("show day 3", day3el)
        day3el.classList.add("visible")
        day3el.classList.remove("hidden")
        day2el.classList.add("hidden")
        day2el.classList.remove("visible")
        day4el.classList.add("hidden")
        day4el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 3) {
      if (day3el && day4el && day5el) {
        console.log("show day 4", day4el)
        day3el.classList.add("hidden")
        day3el.classList.remove("visible")
        day4el.classList.add("visible")
        day4el.classList.remove("hidden")
        day5el.classList.add("hidden")
        day5el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 4) {
      if (day4el && day5el && day6el) {
        console.log("show day 5", day5el)
        day4el.classList.add("hidden")
        day4el.classList.remove("visible")
        day5el.classList.add("visible")
        day5el.classList.remove("hidden")
        day6el.classList.add("hidden")
        day6el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 5) {
      if (day5el && day6el && day7el) {
        console.log("show day 6", day6el)
        day5el.classList.add("hidden")
        day5el.classList.remove("visible")
        day6el.classList.add("visible")
        day6el.classList.remove("hidden")
        day7el.classList.add("hidden")
        day7el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 6) {
      if (day6el && day7el && day8el) {
        console.log("show day 7", day6el)
        day6el.classList.add("hidden")
        day6el.classList.remove("visible")
        day7el.classList.add("visible")
        day7el.classList.remove("hidden")
        day8el.classList.add("hidden")
        day8el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 7) {
      if (day7el && day8el && day9el) {
        console.log("show day 8", day6el)
        day7el.classList.add("hidden")
        day7el.classList.remove("visible")
        day8el.classList.add("visible")
        day8el.classList.remove("hidden")
        day9el.classList.add("hidden")
        day9el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 8) {
      if (day8el && day9el && day10el) {
        console.log("show day 9", day6el)
        day8el.classList.add("hidden")
        day8el.classList.remove("visible")
        day9el.classList.add("visible")
        day9el.classList.remove("hidden")
        day10el.classList.add("hidden")
        day10el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 9) {
      if (day9el && day10el && day11el) {
        console.log("show day 10", day10el)
        day9el.classList.add("hidden")
        day9el.classList.remove("visible")
        day10el.classList.add("visible")
        day10el.classList.remove("hidden")
        day11el.classList.add("hidden")
        day11el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 10) {
      if (day10el && day11el && day12el) {
        console.log("show day 11", day11el)
        day10el.classList.add("hidden")
        day10el.classList.remove("visible")
        day11el.classList.add("visible")
        day11el.classList.remove("hidden")
        day12el.classList.add("hidden")
        day12el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 11) {
      if (day11el && day12el && day13el) {
        console.log("show day 12", day12el)
        day11el.classList.add("hidden")
        day11el.classList.remove("visible")
        day12el.classList.add("visible")
        day12el.classList.remove("hidden")
        day13el.classList.add("hidden")
        day13el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 12) {
      if (day12el && day13el && day14el) {
        console.log("show day 13", day13el)
        day12el.classList.add("hidden")
        day12el.classList.remove("visible")
        day13el.classList.add("visible")
        day13el.classList.remove("hidden")
        day14el.classList.add("hidden")
        day14el.classList.remove("visible")
      }
    }
    if (e.activeIndex === 13) {
      if (day13el && day14el) {
        console.log("show day 14", day14el)
        day13el.classList.add("hidden")
        day13el.classList.remove("visible")
        day14el.classList.add("visible")
        day14el.classList.remove("hidden")
      }
    }
  }
  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        onSlideChange={(e) => handleSlideChange(e)}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[0].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[0].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[0].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[1].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[1].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[1].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[2].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[2].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[2].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[3].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[3].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[3].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[4].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[4].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[4].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[5].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[5].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[5].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[6].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[6].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[6].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[7].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[7].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[7].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[8].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[8].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[8].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[9].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[9].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[9].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[10].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[10].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[10].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[11].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[11].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[11].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[12].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[12].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[12].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {!isDesktop && challengeQuestion(dailyChallenge[13].id)}
          <div className="challenge-question challenge-question--gallery">
            {isDesktop && challengeQuestion(dailyChallenge[13].id)}
            <div className="challenge-content">
              <h1 className="text display8">{dailyChallenge[13].challenge}</h1>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default InstagramFeed
