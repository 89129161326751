export const slideUpExit = {
  show: { opacity: 1, y: 0 },
  hide: { opactiy: 0, y: "-400%" },
}

export const slideRightExit = {
  show: { opacity: 1, x: 20 },
  hide: { opactiy: 0, x: 500 },
}

export const slideUpEntrance = {
  show: { opacity: 1, y: "0%" },
  hide: { opacity: 0,y: "50%" },
  leave: { opacity: 0,y: "-50%" },
}

export const slideUpEntranceQuick = {
  show: { opacity: 1, y: 0 },
  hide: { opacity: 0,y: 50 },
}

export const modalEntrance = {
  show: { opacity: 1, y: 0 },
  hide: { opacity: 0,y: -800 },
  leave: { opacity: 0,y: 399 },
}

export const slideDown = {
  show: {y: 0, opacity: 1},
  hide: {y: "-100%", opacity: 0}
}





export const fadeOut = {
  show: {opacity: 1},
  hide: {opacity: 0}
}



