import React from "react"

export const useDesktop = () => {
  const [isDesktop, setIsDesktop] = React.useState(true)

  function setDesktopBoolean() {
    if (window.innerWidth > 1016) {
      setIsDesktop(true)
    } else {
      setIsDesktop(false)
    }
  }

  React.useEffect(() => {
    setDesktopBoolean()
    window.addEventListener("resize", () => {
      setDesktopBoolean()
    })

    return () => {
      window.removeEventListener("resize", () => null)
    }
  }, [])

  return isDesktop
}
