import React from "react"
// import countdown from "./countdown"
// import countdown from "./countdown"
// Pkgs
// import { motion } from "framer-motion"
// import { slideUpEntranceQuick } from "../motion"

import Spacer from "../elements/spacer"
import { useDesktop } from "../../hooks/useDesktop"
import InstagramCta from "../buttons/instagram_cta"

// Data
import { data } from "../../data/daily_challenges"
// const d = new Date()
// const h = 24 - d.getHours()
// const m = 60 - d.getMinutes()

const Challenge: React.FC = () => {
  const isDesktop = useDesktop()

  // const [hrs, setHrs] = React.useState(h)
  // const [min, setMin] = React.useState(m)

  // React.useEffect(() => {
  //   const timer = setInterval(function time() {
  //     // const future_date: any = new Date("2020-12-14T08:00:00.0000")
  //     // const date_now: any = new Date()

  //     // const time: any = countdown(future_date, date_now) as any
  //     // const hoursLeft = time.days * 24 + time.hours
  //     // setHrs(hoursLeft)
  //     // setMin(time.minutes)
  //   }, 1000)

  //   return () => {
  //     clearInterval(timer)
  //   }
  // })

  return (
    <div className="challenge homepage">
      <>
        <div className="challenge-question">
          <div className="challenge-question__welcome-text caption1">
            Challenge {data[13].id} / {data.length} @rightbrainworkout
          </div>
          <Spacer deviceSize="m" desktopSize="l" />
          <div className="challenge-content">
            <h1 className="text display1">{data[13].challenge}</h1>
          </div>
          <Spacer deviceSize="m" desktopSize="l" />
          <p className="caption1">
            Participate and celebrate your weird and wonderful by tagging us at
          </p>
          <Spacer deviceSize="m" desktopSize="xs" />
          <InstagramCta />
        </div>

        {isDesktop && (
          <div className="next-challenge">
            <Spacer deviceSize="m" desktopSize="l" />
            <div className="caption1">Next challenge</div>
            <div className="challenge-content">
              <div className="challenge-timer">
                <div className="challenge-timer-items">
                  <span className="challenge-timer-item display3">0</span>
                  <span className="challenge-timer-item display4">HR</span>
                </div>
                <div className="challenge-timer-items">
                  <span className="challenge-timer-item display3">0</span>
                  <span className="challenge-timer-item display4">MIN</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default Challenge
