import React from "react"
import classnames from "classnames"

export interface PageTypes {
  children: any
  hideContent: boolean
  id: string
  modifierClass?: string
}
const Page: React.FC<PageTypes> = ({
  children,
  hideContent,
  id,
  modifierClass,
}) => {
  const modifierClassNames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <section
      className={`page ${modifierClassNames} ${hideContent ? "hide" : "show"}`}
      id={id}
    >
      <div className="page-content">{children}</div>
    </section>
  )
}

export default Page
