import React from "react"
import classnames from "classnames"

// A Spacer that is used to break up blocks of content both horizontally and vertically

export type Sizes =
  | "xn"
  | "n"
  | "xxs"
  | "xs"
  | "s"
  | "m"
  | "xm"
  | "l"
  | "xl"
  | "xxl"
  | "g"

interface SpacerTypes {
  deviceSize?: Sizes
  deviceLayout?: string
  deviceOnly?: boolean

  desktopSize?: Sizes
  desktopLayout?: string
  desktopOnly?: boolean
}

const Spacer: React.FC<SpacerTypes> = ({
  deviceSize,
  deviceLayout,
  desktopSize,
  desktopLayout,
  desktopOnly,
  deviceOnly,
}) => {
  const spacerClassnames = classnames({
    [`spacer-size--phone-${deviceSize}`]: deviceSize,
    [`spacer-layout--phone-${deviceLayout}`]: deviceLayout,
    [`spacer-size--desktop-${desktopSize}`]: desktopSize,
    [`spacer-layout--desktop-${desktopLayout}`]: desktopLayout,
    "desktop-only": desktopOnly,
    "device-only": deviceOnly,
  })
  return <span className={`spacer ${spacerClassnames}`} />
}

export default Spacer
