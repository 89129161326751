import React from "react"
import { motion } from "framer-motion"

// Hooks
import { useDesktop } from "../../hooks/useDesktop"

// Motion
import { slideRightExit } from "../motion"

interface Props {
  isVisible: boolean
}

const WantMoreCallout: React.FC<Props> = ({ isVisible }) => {
  const isDesktop = useDesktop()
  return (
    <>
      {isDesktop ? (
        <motion.a
          href="https://www.rightbrainworkout.com"
          target="_blank"
          rel="noreferrer"
          className="want-more-callout"
          animate={isVisible ? "show" : "hide"}
          variants={slideRightExit}
          transition={{
            duration: 0.5,
            type: "spring",
            stiffness: 70,
            // damping: 13,
          }}
        >
          <small className="text">
            Want more? Retrain your brain to be more creative in just 10 weeks
          </small>
        </motion.a>
      ) : (
        <a
          href="https://www.rightbrainworkout.com"
          target="_blank"
          rel="noreferrer"
          className="want-more-callout want-more-callout--mobile"
        >
          <small className="text">
            Want more? Retrain your brain to be more creative in just 10 weeks
          </small>
        </a>
      )}
    </>
  )
}

export default WantMoreCallout
