import React from "react"
import { NavLink } from "react-router-dom"
import { HashLink as Link } from "react-router-hash-link"

import classnames from "classnames"
import { useLocation } from "react-router-dom"

// Hooks
import { useDesktop } from "../../hooks/useDesktop"

// Vars
import { urlPaths } from "../../_globals"

// Motion
import { motion } from "framer-motion"
import { slideUpExit, fadeOut } from "../motion"

// UI
import PrimaryBtn from "../buttons/primary_btn"
import SocialIconGroup from "../ui/social_icon_group"
import WantMoreCallout from "../ui/want_more_callout"
import Spacer from "../elements/spacer"

// Helpers

interface Props {
  handleJoinChallengeClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  isVisible: boolean
  modifierClass?: string
  handleDeviceOnClick: () => void
  isHomeAboutVisible: boolean
  darkMode: boolean
}

const Navigation: React.FC<Props> = ({
  handleJoinChallengeClick,
  isVisible,
  modifierClass,
  handleDeviceOnClick,
  isHomeAboutVisible,
  darkMode,
}) => {
  const [isDarkMode, setIsDarkMode] = React.useState(false)
  const location = useLocation()
  React.useEffect(() => {
    if (location.pathname === urlPaths.about.path) {
      setIsDarkMode(true)
    } else {
      setIsDarkMode(false)
    }
  }, [location.pathname])

  const aboutClassNames = classnames("navigation__item-link", {
    "navigation__item-link--active": isHomeAboutVisible,
  })
  const modifierClassNames = classnames("navigation", {
    [`${modifierClass}`]: modifierClass,
  })

  const primaryBtnClassNames = classnames("navigation-cta", {
    "dark-mode": darkMode || isDarkMode,
  })

  const isDesktop = useDesktop()

  return (
    <motion.nav
      className={modifierClassNames}
      animate={isVisible ? "show" : "hide"}
      variants={isDesktop ? slideUpExit : fadeOut}
      transition={{
        duration: 1,
        type: "spring",
        stiffness: 140,
      }}
    >
      <ul role="navigation" className="navigation__items">
        {/* <li className="navigation__item" onClick={handleDeviceOnClick}>
          <NavLink
            to={urlPaths.today.path}
            className="navigation__item-link"
            activeClassName="navigation__item-link--active"
          >
            {urlPaths.today.label}
          </NavLink>
        </li> */}
        <li className="navigation__item" onClick={handleDeviceOnClick}>
          <NavLink
            to={urlPaths.gallery.path}
            className="navigation__item-link"
            activeClassName="navigation__item-link--active"
          >
            {urlPaths.gallery.label}
          </NavLink>
        </li>
        <li className="navigation__item" onClick={handleDeviceOnClick}>
          <Link
            to={`${urlPaths.home.path}#${urlPaths.about.label.toLowerCase()}`}
            className={aboutClassNames}
          >
            {urlPaths.about.label}
          </Link>
        </li>
      </ul>

      <PrimaryBtn
        modifierClass={primaryBtnClassNames}
        onClick={handleJoinChallengeClick}
        text="Join the 14 day challenge"
      />
      {!isDesktop && <Spacer deviceSize="s" />}
      <SocialIconGroup />
      {!isDesktop && <WantMoreCallout isVisible={true} />}
    </motion.nav>
  )
}

export default Navigation
