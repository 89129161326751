import React from "react"
import { motion } from "framer-motion"
import classnames from "classnames"

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  text: string
  modifierClass?: string
}
const PrimaryBtn: React.FC<Props> = ({ onClick, text, modifierClass }) => {
  const modifierClassNames = classnames("primary-btn", {
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <motion.button
      onClick={onClick}
      className={`${modifierClassNames} typograpgy__primary-btn`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {text}
    </motion.button>
  )
}

export default PrimaryBtn
