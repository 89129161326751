import React from "react"
interface Props {
  isVisible: boolean
}
const MouseFollowerImages: React.FC<Props> = ({ isVisible }) => {
  console.log("Mouse has loaded")
  return (
    <div className={`mouse-follower content ${isVisible ? "show" : "hide"}`}>
      <img className="content__img" src="./images/Vector-9.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-9.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-8.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-8.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-7.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-7.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-6.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-6.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-5.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-5.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-4.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-4.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-3.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-3.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-2.svg" alt="circle" />
      <img className="content__img" src="./images/Vector-2.svg" alt="circle" />
    </div>
  )
}

export default MouseFollowerImages
