export const urlPaths = {
  about: {
    label: "About", 
    path: "/about",
  },
  today: {
    label: "Today", 
    path: "/today",
  },
  gallery: {
    label: "Gallery", 
    path: "/gallery",
  },
  home: {
    label: "Home", 
    path: "/",
  },
  join: {
    label: "Join", 
    path: "/join",
  },
  privacy: {
    label: "Privacy", 
    path: "/privacy",
  }
}
