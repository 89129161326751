import React from "react"
import Logo from "./logo"
import classnames from "classnames"

interface Props {
  logoOnClick: () => void
  modifierClass?: string
}

const Header: React.FC<Props> = ({ children, logoOnClick, modifierClass }) => {
  const modifierClassNames = classnames("header", {
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <header className={modifierClassNames}>
      <Logo onClick={logoOnClick} />
      <div className="header__content">{children}</div>
    </header>
  )
}

export default Header
