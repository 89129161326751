import React from "react"
import { Link } from "react-router-dom"
import LogoWhite from "../svgs/logo_white"
import LogoRgb from "../svgs/logo_rgb"

// Vars
import { urlPaths } from "../../_globals"

interface Props {
  onClick: () => void
}

const Logo: React.FC<Props> = ({ onClick }) => {
  const [isMoused, setIsMoused] = React.useState(false)

  return (
    <div
      onClick={onClick}
      className="logo"
      onMouseEnter={() => setIsMoused(true)}
      onMouseLeave={() => setIsMoused(false)}
    >
      <Link to={urlPaths.home.path}>
        <LogoWhite isHovererd={isMoused} />
        <LogoRgb isHovererd={isMoused} />
      </Link>
    </div>
  )
}

export default Logo
