import React from "react"

const SocialIconGroup = () => {
  return (
    <ul className="social-icon-group">
      <li className="social-icon social-icon--linkedin">
        <a
          href="https://www.linkedin.com/company/the-right-brain-workout/"
          target="_blank"
          rel="noreferrer"
        >
          <span>LinkedIn</span>
        </a>
      </li>
      <li className="social-icon social-icon--facebook">
        <a
          href="https://www.facebook.com/rightbrainworkout/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Facebook</span>
        </a>
      </li>
      <li className="social-icon social-icon--instagram">
        <a
          href="https://www.instagram.com/rightbrainworkout/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Instagram</span>
        </a>
      </li>
    </ul>
  )
}

export default SocialIconGroup
