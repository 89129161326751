import React from "react"
import { motion } from "framer-motion"

interface Props {}
const InstagramCta: React.FC<Props> = () => {
  return (
    <motion.a
      href="https://www.instagram.com/rightbrainworkout"
      className="instagram-cta typograpgy__body2"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      rightbrainworkout
    </motion.a>
  )
}

export default InstagramCta
