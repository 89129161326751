import React from "react"
import { useLocation } from "react-router-dom"
const embededSocial = document.querySelectorAll(".embedsocial-story-temp")

export const useShowEmbedSocial = (path: string): null => {
  const location = useLocation()

  React.useEffect(() => {
    if (embededSocial) {
      console.log("show embeded social")
      if (location.pathname === path) {
        for (let i = 0; i < embededSocial.length; i++) {
          embededSocial[i].classList.remove("hide-feed")
          embededSocial[i].classList.add("show-feed")
        }
      }
    }
  })
  return null
}

export const useHideEmbedSocial = (path: string): null => {
  const location = useLocation()

  React.useEffect(() => {
    if (embededSocial) {
      if (location.pathname === path) {
        console.log("hide embeded social")
        for (let i = 0; i < embededSocial.length; i++) {
          embededSocial[i].classList.remove("show-feed")
          embededSocial[i].classList.add("hide-feed")
        }
      }
    }
  })
  return null
}
