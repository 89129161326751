export const data = [
  {
   id: "01",
   challenge: "Write a limerick about the Cow from Downtown."
  },
  {
   id: "02",
   challenge: "Describe how the first wheel was ever made."
  },
  {
   id: "03",
   challenge: "How would you achieve world peace using tomatoes and fluffy toys?"
  },
  {
   id: "04",
   challenge: "You have discovered how to achieve alchemy. But you can only turn cats into gold. Do you do it? And why?"
  },
  {
   id: "05",
   challenge: "Sing the chorus of your hit new song, 'I’m in love with a mushroom, so deal with it, Dad!"
  },
  {
   id: "06",
   challenge: "Perform the new dance craze, 'dunzing'."
  },
  {
   id: "07",
   challenge: "Take a photo of the most boring thing where you are right now, and then describe why it’s inspiring."
  },
  {
   id: "08",
   challenge: "Film a plant and whistle a tune to make it grow."
  },
  {
   id: "09",
   challenge: "Build a sandwich without using any food."
  },
  {
   id: "10",
   challenge: "Write a new second verse to Advance Australia Fair."
  },
  {
   id: "11",
   challenge: "Draw a spaceship to take you to your new home on Saturn."
  },
  {
   id: "12",
   challenge: "Design Donald Trump’s new hairstyle."
  },
  {
   id: "13",
   challenge: "Draw the scariest snail in history."
  },
  {
   id: "14",
   challenge: "What’s an app the world needs right now?"
  }
]