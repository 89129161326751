import React from "react"
import Challenge from "../components/ui/challenge"
import { urlPaths } from "../_globals"
import { useHideEmbedSocial } from "../hooks"
import Page from "../components/page"

const Home = (props: any) => {
  return (
    <>
      {useHideEmbedSocial(`${urlPaths.home.path}`)}

      <Page {...props} id="home">
        <Challenge />
      </Page>
    </>
  )
}

export default Home
