import React from "react"

interface Props {
  alt: string
  src: string
  modifierClass?: string
}
const Icon: React.FC<Props> = ({ alt, modifierClass, src }) => {
  const iconModifier = `${modifierClass ? modifierClass : ""}`
  return (
    <>
      <img className={`icon ${iconModifier}`} src={src} alt={alt} />
    </>
  )
}

export default Icon
