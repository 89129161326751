import React from "react"
import { useShowEmbedSocial } from "../hooks"

import Page from "../components/page"

import { urlPaths } from "../_globals"

import InstagramFeed from "../components/instagram_feed"

const Gallery = (props: any) => {
  return (
    <>
      {useShowEmbedSocial(`${urlPaths.gallery.path}`)}
      <Page {...props} modifierClass="gallery">
        <section className="section--instagram-feed">
          <InstagramFeed />
        </section>
      </Page>
    </>
  )
}

export default Gallery
