import React, { lazy, Suspense } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom"

// Hooks
import { useDesktop } from "./hooks/useDesktop"

// Vars
import { urlPaths } from "./_globals"

// Pkgs
import { Dialog } from "@reach/dialog"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

// Styles
import "./App.scss"
import "@reach/dialog/styles.css"

// JS
import Header from "./components/ui/header"
import Navigation from "./components/ui/navigation"
import WantMoreCallout from "./components/ui/want_more_callout"
import { slideUpEntranceQuick } from "./components/motion"

// Elements
import Icon from "./components/elements/icon"
import closeIcon from "./images/icons/close.svg"
import LoadingSpinner from "./components/elements/loading_spinner"

// Pages
import Today from "./pages/today"
import Gallery from "./pages/gallery"
import Home from "./pages/home"
import MouseFollowerImages from "./components/elements/mouse_follower_images"
const Join = lazy(() => import("./pages/join"))
const About = lazy(() => import("./pages/about"))
const Privacy = lazy(() => import("./pages/privacy"))

interface Props {
  children: React.ReactNode
}
const App: React.FC<Props> = ({ children }, props) => {
  const [showDialog, setShowDialog] = React.useState(false)
  const open = () => {
    setShowDialog(true)
    setToggle(false)
  }
  const close = () => {
    setShowDialog(false)
  }

  const [toggle, setToggle] = React.useState(false)
  const toggleHamburger = () => {
    setToggle(!toggle)
  }

  const isDesktop = useDesktop()
  const [aboutPageRef, aboutPageInView] = useInView({
    threshold: isDesktop ? 0.4 : 0.1,
  })

  const [pageScrolled, setPageScrolled] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 60) {
        setPageScrolled(true)
      } else {
        setPageScrolled(false)
      }
    })
    return () => {
      window.removeEventListener("scroll", () => null)
    }
  }, [])

  React.useEffect(() => {
    const body = document.querySelector("body")
    if (toggle && body) {
      body.classList.add("locked")
    }

    if (!toggle && body) {
      body.classList.remove("locked")
    }
  })

  const handleLogoOnClick = () => {
    setToggle(false)
    window.location.href = "/#"
    if (showDialog) setShowDialog(false)
    else return
  }

  return (
    <>
      <div className="layout">
        <Router>
          <Header
            logoOnClick={handleLogoOnClick}
            modifierClass={`${pageScrolled ? "scrolled" : "default"}`}
          >
            <Navigation
              isHomeAboutVisible={aboutPageInView}
              handleDeviceOnClick={toggleHamburger}
              isVisible={!showDialog}
              handleJoinChallengeClick={open}
              modifierClass={toggle ? "show" : "hide"}
              darkMode={aboutPageInView}
            />
          </Header>

          {!showDialog && (
            <button
              onClick={toggleHamburger}
              className={`navigation-toggler ${toggle ? "on" : "off"}`}
            >
              <span
                className={`navigation-hamburger navigation-hamburger--top ${
                  toggle ? "on" : "off"
                }`}
              />
              <span
                className={`navigation-hamburger navigation-hamburger--middle   ${
                  toggle ? "hide" : "show"
                }`}
              />
              <span
                className={`navigation-hamburger navigation-hamburger--bottom  ${
                  toggle ? "on" : "off"
                }`}
              />
            </button>
          )}
          {children}

          {/* Routed components */}
          <div className="pages">
            <Switch>
              <Route exact path={urlPaths.home.path}>
                <Home hideContent={showDialog} />

                {!showDialog && (
                  <section
                    ref={aboutPageRef}
                    className="landing__section--about"
                  >
                    <Suspense fallback={<LoadingSpinner />}>
                      <About hideContent={false} isVisible={aboutPageInView} />
                    </Suspense>
                  </section>
                )}
                <div
                  className={`background  ${
                    aboutPageInView
                      ? "background__about background__about--entered"
                      : "background__about background__about--exited"
                  }`}
                />
              </Route>
              <Route exact path={urlPaths.today.path}>
                <Today hideContent={showDialog} />
              </Route>
              <Route path={urlPaths.gallery.path}>
                <Gallery hideContent={showDialog} />
              </Route>
              <Route path={urlPaths.about.path}>
                <Suspense fallback={<LoadingSpinner />}>
                  <About isVisible={!showDialog} hideContent={showDialog} />
                </Suspense>
              </Route>
            </Switch>
          </div>

          {isDesktop && (
            <>
              <WantMoreCallout isVisible={isDesktop ? !showDialog : true} />
              <MouseFollowerImages isVisible={true} />
            </>
          )}
        </Router>

        <div className="background" />
      </div>
      <HashRouter>
        {showDialog && (
          <button className="modal-button--close" onClick={close}>
            <Icon src={closeIcon} alt="close modal" />
          </button>
        )}
        <Route exact path={urlPaths.privacy.path}>
          <Suspense fallback={<LoadingSpinner />}>
            <Privacy onCloseClick={() => setShowDialog(true)} />
          </Suspense>
        </Route>
        <Dialog
          className="modal"
          isOpen={showDialog}
          onDismiss={close}
          aria-label="Join the challenge form modal"
        >
          <motion.div
            className="modal-content"
            initial="hide"
            animate={showDialog ? "show" : "leave"}
            variants={slideUpEntranceQuick}
            transition={{
              duration: 0.5,
              ease: "easeOut",
            }}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <Join handlePrivacyOnClick={close} />
            </Suspense>
          </motion.div>
        </Dialog>
      </HashRouter>
    </>
  )
}

export default App
