import React from "react"
import Page from "../components/page"
import Challenge from "../components/ui/challenge"
import { urlPaths } from "../_globals"
import { useHideEmbedSocial } from "../hooks"

const Today = (props: any) => {
  return (
    <>
      {useHideEmbedSocial(`${urlPaths.today.path}`)}
      <Page {...props} modifierClass="challenge">
        <Challenge />
      </Page>
    </>
  )
}

export default Today
